import React from "react"
import IconBase from "./Icon"

export default function RegisterIcon({ active }) {
  return (
    <IconBase active={active} width="76" height="69" className="register-svg">
      <g fill="#FFF" fill-rule="evenodd">
        <path d="M48.945 60.987v4.764H3.055V2.97h45.89V38.17L52 35.162V1.504C52 .674 51.316 0 50.473 0H1.487A1.516 1.516 0 000 1.504v65.792c0 .83.684 1.504 1.527 1.504h48.946c.843 0 1.527-.674 1.527-1.504v-9.317l-3.055 3.008z" />
        <path d="M42.7 50.27l-.29 4.272 4.466-.277 25.392-24.3-4.126-3.945L42.7 50.27zm-1.95 7.33c-.854.002-1.548-.659-1.55-1.477l.003-.102.433-6.501c.017-.372.179-.724.454-.987l26.908-25.7a1.597 1.597 0 012.186 0l6.363 6.087a1.435 1.435 0 010 2.092L48.65 56.752a1.582 1.582 0 01-1.031.434l-6.797.414h-.072zM42.523 17.6H19.877c-.815 0-1.477-.538-1.477-1.2 0-.662.662-1.2 1.477-1.2h22.646c.815 0 1.477.538 1.477 1.2 0 .662-.662 1.2-1.477 1.2M42.523 31.2H19.877c-.815 0-1.477-.538-1.477-1.2 0-.662.662-1.2 1.477-1.2h22.646c.815 0 1.477.538 1.477 1.2 0 .662-.662 1.2-1.477 1.2M33.625 46.4h-13.65c-.87 0-1.575-.538-1.575-1.2 0-.662.706-1.2 1.575-1.2h13.65c.87 0 1.575.538 1.575 1.2 0 .662-.706 1.2-1.575 1.2M11.317 17.6a1.566 1.566 0 01-1.003-.408l-2.8-2.588c-.508-.607-.387-1.481.27-1.951a1.604 1.604 0 011.808-.025l1.725 1.614 3.752-3.469c.608-.524 1.56-.492 2.127.07a1.315 1.315 0 010 1.897l-4.805 4.443c-.283.267-.67.417-1.074.417M11.317 31.2a1.566 1.566 0 01-1.003-.408l-2.8-2.588c-.508-.607-.387-1.481.27-1.951a1.604 1.604 0 011.808-.025l1.725 1.614 3.752-3.469c.608-.524 1.56-.492 2.127.07a1.315 1.315 0 010 1.897l-4.805 4.443c-.283.267-.67.417-1.074.417M11.317 46.4a1.566 1.566 0 01-1.003-.408l-2.8-2.588c-.508-.607-.387-1.481.27-1.951a1.604 1.604 0 011.808-.025l1.725 1.614 3.752-3.469c.608-.524 1.56-.492 2.127.07a1.315 1.315 0 010 1.897l-4.805 4.443c-.283.267-.67.417-1.074.417" />
      </g>
    </IconBase>
  )
}
