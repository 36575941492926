import React from "react"
import IconBase from "./Icon"

export default function SearchIcon({ active }) {
  return (
    <IconBase active={active} width="92" height="70">
      <g fill="#FFF" fill-rule="evenodd">
        <path d="M88.654 4.3H43.19c-1.396 0-2.529-.963-2.529-2.15C40.662.962 41.795 0 43.191 0h45.463c1.396 0 2.528.962 2.528 2.15 0 1.187-1.132 2.15-2.528 2.15M88.654 20.838h-28.64c-1.397 0-2.528-.963-2.528-2.15 0-1.187 1.131-2.15 2.527-2.15h28.64c1.397 0 2.529.963 2.529 2.15 0 1.187-1.132 2.15-2.528 2.15M87.139 37.377H67.683c-1.396 0-2.528-.963-2.528-2.15 0-1.188 1.132-2.15 2.528-2.15H87.14c1.396 0 2.528.962 2.528 2.15 0 1.187-1.132 2.15-2.528 2.15M42.373 58.28c-10.162 8.514-25.271 7.967-34.797-1.643-10.101-10.19-10.101-26.71 0-36.9 10.102-10.188 26.48-10.188 36.581 0 9.22 9.3 10.026 23.875 2.415 34.096L56.998 64.35a3.076 3.076 0 01-.007 4.335 3.032 3.032 0 01-4.296.006L42.373 58.28zm-1.232-4.818c8.435-8.436 8.435-22.112 0-30.547-8.435-8.436-22.112-8.436-30.547 0-8.435 8.435-8.435 22.111 0 30.547 8.435 8.435 22.112 8.435 30.547 0z" />
      </g>
    </IconBase>
  )
}
